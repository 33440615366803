<template>
    <div class="row">
        <div class="col text-center">
            <cropper
                ref="cropper"
                class="cropper mx-auto"
                :src="img"
                :stencil-props="{
                    aspectRatio: proportion,
                    movable: true,
                    resizable: true,
                }"

            />

            <BAlert show variant="warning" class="mt-2" style="width: 100%">
                Para salvar a imagem clique em Recortar
            </BAlert>

            <button @click="cancelCrop" type="button" class="btn btn-outline-danger mt-3">Cancelar</button>
            <button @click="cropImage" type="button" class="btn btn-outline-info mt-3 ml-2">Recortar</button>
        </div>
    </div>
</template>

<script>
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default {
    components: {
        Cropper
    },

    props: {
        img: {
            required: true
        },
        proportion: {
            required: true,
            type: Number
        }
    },

    methods: {
        cropImage() {

            const { canvas } = this.$refs.cropper.getResult();

            canvas.toBlob((blob) => {
                this.$emit('set-img', blob);
			});
        },

        cancelCrop() {
            this.$emit('reset-logo')
        }
    },
}
</script>

<style scoped=true>

.cropper {
	height: 400px;
	width: 400px;
	background: #DDD;
}
</style>
