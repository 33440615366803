import Swal from "sweetalert2";

/**
 *
 * @param event
 * @param width
 * @param height
 * @return {{img: null, alert: boolean, url: null}}
 */
export function handleImg(event, width, height) {
    return new Promise((resolve, reject) => {
        let img = null;
        let alert = false;
        let url = null;

        const input = event.target;
        if (input.files && input.files[0]) {
            const file = input.files[0];

            // Verifique se o arquivo é uma imagem
            if (!file.type.startsWith('image/')) {
                // Limpe o conteúdo do input se o arquivo não for uma imagem
                input.value = '';
                Swal.fire({
                        title: 'Arquivo Inválido!',
                        text: "O arquivo selecionado não é uma imagem.",
                        icon: 'error',
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "Ok",
                    })
                    .then((result) => {
                        return !!result.value;

                    })
                    .catch(() => {
                        return false;
                    });
                return;
            }

            const reader = new FileReader();

            reader.onload = (e) => {
                img = e.target.result;

                const image = new Image();
                image.onload = () => {
                    // Verifique se as dimensões da imagem são menores do que as permitidas
                    alert = image.width < width || image.height < height;

                    resolve({ img, url, alert });
                };

                image.onerror = reject;
                // Defina o src após configurar o onload e onerror
                image.src = e.target.result;
            };

            reader.onerror = reject;
            reader.readAsDataURL(input.files[0]);
        } else {
            reject("Nenhum arquivo selecionado");
        }
    });
}




/**
 *
 * @param blob
 * @return {{image: *, url: string}}
 */
export function setImg(blob) {
    const url = URL.createObjectURL(blob);
    const image = new File([blob], "cropped-image.png", {type: 'image/png'});
    return {
        url,
        image
    }
}
